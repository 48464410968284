import React from "react"
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const NavItem = styled(AniLink)`
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  letter-spacing: 1px;
  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #005b4b;
    height: 1px;
    transition: width 0.4s ease-in;
  }

  :hover {
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 15px 0 10px;
    font-size: 1.5rem;
    z-index: 6;
  }
`
const NavbarLinks = () => {
  return (
    <>
      <NavItem to="/" fade duration={0.5} activeClassName="active">
        Naslovna
      </NavItem>
      <NavItem to="/upotreba/" fade duration={0.5} activeClassName="active">
        Upotreba
      </NavItem>
      <NavItem to="/drbach/" fade duration={0.5} activeClassName="active">
        Dr. Bach
      </NavItem>
      <NavItem to="/blog/" fade duration={0.5} activeClassName="active">
        Iskustva
      </NavItem>
      <NavItem to="/kontakt/" fade duration={0.5} activeClassName="active">
        Kontakt
      </NavItem>
    </>
  )
}

export default NavbarLinks
