import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import gsap from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import "normalize.css"
import Navbar from "../Navbar/Navbar"
import "./layout.scss"

const Layout = ({ children }) => {
  gsap.registerPlugin(ScrollToPlugin)
  const arrowRef = useRef(null)

  function goToTop() {
    gsap.to(window, {
      scrollTo: { y: 0 },
      duration: 1.5,
      ease: "power4",
    })
  }

  function showArrow() {
    if (scrollDirection === "up" && scrollY < 50) {
      arrowRef.current.classList.remove("show")
    } else if (scrollDirection === "up") {
      arrowRef.current.classList.add("show")
    } else if (arrowRef.current.classList.contains("show")) {
      arrowRef.current.classList.remove("show")
    }
  }

  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [bodyOffset, setBodyOffset] = useState(
    typeof window === "undefined" || !window.document
      ? 0
      : document.body.getBoundingClientRect()
  )
  const [scrollY, setScrollY] = useState(bodyOffset.top)
  const [scrollDirection, setScrollDirection] = useState()

  const listener = (fn, wait) => {
    setBodyOffset(
      typeof window === "undefined" || !window.document
        ? 0
        : document.body.getBoundingClientRect()
    )
    setScrollY(-bodyOffset.top)
    setScrollDirection(lastScrollTop > -bodyOffset.top ? "up" : "down")
    setLastScrollTop(-bodyOffset.top)
    showArrow()
  }
  useEffect(() => {
    window.addEventListener("scroll", listener)
    return () => {
      window.removeEventListener("scroll", listener)
    }
  })
  return (
    <div className="main-grid">
      <button
        ref={arrowRef}
        type="button"
        className="arrow"
        onClick={e => goToTop(e)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="14"
          viewBox="0 0 10.682 17.121"
        >
          <path
            id="Path_101"
            d="M4725,896l7.5,7.5L4725,911"
            transform="translate(-4723 -895)"
            fill="none"
            strokeWidth="3px"
          />
        </svg>
      </button>
      <Navbar className="navbar-grid" />
      <main id="viewport">{children}</main>
      <footer>© {new Date().getFullYear()}, Bahove kapi</footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
